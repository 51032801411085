import React, { useContext, useState } from "react";
import { Col, Menu } from "antd";
import { ProductsContext } from "../products/context/ProductsContext";
import { Circle } from "react-feather";

const FilterFields = ({
  categories = [],
  references = [],
  colors = [],
  initialCategorys,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const { searchCustom } = useContext(ProductsContext);
  const { getProductsFiltered } = searchCustom;

  const getIconWithBackground = (type) => {
    const backgroundColor =
      type === "categorías"
        ? "#f9ebea"
        : type === "referencias"
        ? "#c5e4b8"
        : "transparent";

    return (
      <div
        style={{
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Circle
          color="white"
          size={16}
          style={{ background: backgroundColor, borderRadius: "50%" }}
        />
      </div>
    );
  };

  const [selectedFilters, setSelectedFilters] = useState({
    search: "",
    category: initialCategorys?.length > 0 ? initialCategorys : "",
    reference: "",
    color: "",
  });

  const handleFilterChange = (filterType, value) => {
    const newFilters = { ...selectedFilters };
    const newSelectedKeys = [...selectedKeys];
    const key = `${filterType.slice(0, 3)}-${value}`;

    if (newFilters[filterType] === value) {
      newFilters[filterType] = "";
      setSelectedKeys(newSelectedKeys.filter((k) => k !== key));
    } else {
      newFilters[filterType] = value;
      setSelectedKeys([...newSelectedKeys, key]);
    }

    setSelectedFilters(newFilters);
    getProductsFiltered(newFilters);
  };

  const getMenuItem = (type, items) => ({
    key: type,
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {getIconWithBackground(type)}
        <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
      </div>
    ),
    children: items.map((item) => ({
      key: `${type.slice(0, 3)}-${item._id || item}`,
      label: (
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          {getIconWithBackground(type)}
          <span>{item.name}</span>
        </div>
      ),
    })),
  });

  const filteredCategories = categories.filter(
    (category) =>
      !(category.name === "Deluxe" || category.name === "Nueva colección")
  );

  const menuItems = [
    getMenuItem("categorías", filteredCategories),
    getMenuItem("referencias", references),
    getMenuItem("colores", colors),
  ];

  return (
    <Col xs={24} md={16}>
      <Menu
        mode="vertical"
        selectable={false}
        style={{
          background: "transparent",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
        items={menuItems}
        onClick={({ key }) => {
          const [type, id] = key.split("-");
          const filterType =
            type === "cat"
              ? "category"
              : type === "ref"
              ? "reference"
              : "color";
          handleFilterChange(filterType, id);
        }}
      />
    </Col>
  );
};

export default FilterFields;
