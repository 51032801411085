import { Button, Col, Row } from "antd";
import styled from "styled-components";

const StyledRow = styled(Row)`
  background: linear-gradient(
    to bottom right,
    rgba(169, 170, 162, 0.9),
    rgba(255, 255, 255, 0.8)
  );

  padding: 20px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const StyledColImage = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 400px;
    border-radius: 16px;
    filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.3));
    transition: opacity 0.8s ease-in-out;
    opacity: 0.85;

    &:hover {
      opacity: 1;
      filter: drop-shadow(0 8px 15px rgba(0, 0, 0, 0.5));
    }
  }
`;

const StyledColText = styled(Col)`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .contentText {
  }

  h2 {
    /* font-size: 1.9rem; */
    color: #333;
    margin-bottom: 16px;
    font-size: ${(props) => (props.MovilSize ? "1rem" : "2.7rem")};

    /* font-family: "Baloo Tamma 2", system-ui; */
    font-family: Montserrat, sans-serif;

    text-transform: uppercase;
    font-weight: 800;
    line-height: 40px;
    text-align: center;
  }

  @media (max-width: 768px) {
    h2 {
      font-family: "Montserrat", sans-serif;

      font-size: 1.5rem;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 16px;
  height: 50px;

  &:hover {
    background-color: white;
    color: black;
  }
`;

export { StyledRow, StyledColImage, StyledColText, StyledButton };
