import React, { useContext } from "react";
import {
  ContenNamePiece,
  ContenNameTypePiece,
  ContensizePiece,
  ContentDescriptionCart,
  ContentItemCart,
  ContentNameProduct,
  ContentProductsCart,
  ContentSelectedSizes,
  PriceItemTotal,
} from "./style";
import { Col, Divider, Row } from "antd";
import Counter from "../products/components/productDetails/components/Counter";
import { ProductsContext } from "../products/context/ProductsContext";
import ButtonDeleteProductCart from "../products/components/productsActions/buttonsProductsActions.js/ButtonDeleteProductCart";

const ListItems = () => {
  const { shoppingCart } = useContext(ProductsContext);
  const { cartItems, onChangeCountItem, onDeleteToCart } = shoppingCart;
  const uploadUrl = process.env.REACT_APP_IMAGES_URL;

  console.log(cartItems);

  return (
    <>
      <ContentProductsCart>
        {cartItems?.map((product) => (
          <ContentItemCart key={product._id}>
            <Col xs={24}>
              <ContentNameProduct>
                <Col xs={24}>
                  <span>{product.name}</span>
                </Col>
              </ContentNameProduct>
            </Col>
            <Col xs={24}>
              <Row className="product" key={product._id}>
                <Col xs={12}>
                  <Row justify={"center"}>
                    <img
                      src={`${uploadUrl}${product.imgs[0].url}`}
                      alt={product.description}
                    />
                  </Row>
                </Col>

                <Col xs={12}>
                  <ContentDescriptionCart>
                    <Row>
                      <Col xs={24}>
                        {product.selectedPiece.map((piece) => (
                          <>
                            <ContentSelectedSizes
                              key={piece.idPiece}
                              gutter={2}
                            >
                              <ContenNamePiece xs={14}>
                                <span>{piece.piece} :</span>
                              </ContenNamePiece>
                              <ContensizePiece xs={10}>
                                <span>{piece?.size?.name}</span>
                              </ContensizePiece>

                            </ContentSelectedSizes>

                            <ContentSelectedSizes
                              key={piece.idPiece}
                              gutter={2}
                            >
                              <ContenNamePiece xs={12} md={14}>
                                <span className="type">Tipo: </span>
                              </ContenNamePiece>
                              <ContenNameTypePiece xs={12} md={4}>

                                <span>{piece.typeName}</span>
                              </ContenNameTypePiece>
                            </ContentSelectedSizes>
                            <Divider/>

                          </>
                        ))}
                      </Col>
                    </Row>
                  </ContentDescriptionCart>
                </Col>

                <Col xs={12}>
                  <Counter
                    addValue={1}
                    removeValue={1}
                    data={product}
                    onChangeCountItem={onChangeCountItem}
                  />
                </Col>
                <Col xs={12}></Col>
                <Col xs={10}>
                  <ButtonDeleteProductCart
                    handleDelete={onDeleteToCart}
                    idItem={product.idItemCart}
                  />
                </Col>
                <Col xs={14}>
                  <PriceItemTotal>
                    <span>
                      Total: $
                      {product.price
                        ? product.price * product.count
                        : product.price}
                      COP
                    </span>
                  </PriceItemTotal>
                </Col>
              </Row>
            </Col>
          </ContentItemCart>
        ))}
      </ContentProductsCart>
    </>
  );
};

export default ListItems;
