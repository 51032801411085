import React from "react";
import {
  StyledButton,
  StyledColImage,
  StyledColText,
  StyledRow,
} from "./style";
import { Col, Row } from "antd";

const PromotionalContainer = () => {
  return (
    <StyledRow gutter={[16, 16]}>
      <StyledColImage xs={24} sm={12}>
        <img
          src="https://res.cloudinary.com/doe7vtjfk/image/upload/v1723304742/289261951_437604288167962_8914865980331154090_n_wrkyn4.jpg"
          alt="Promotional"
        />
      </StyledColImage>
      <StyledColText xs={24} md={12}>
        <Row className="contentText">
          <Col md={24}>
            <Row justify={"center"}>
              <h2>Disfruta de nuestra línea exclusiva</h2>
            </Row>
          </Col>
          <Col xs={24} md={24}>
            <Row justify={"center"}>
              <StyledButton
                onClick={() => (window.location.href = "/news")}
              >
                Ver más
              </StyledButton>{" "}
            </Row>
          </Col>
        </Row>
      </StyledColText>
    </StyledRow>
  );
};

export default PromotionalContainer;
