import { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  CarouselControl,
  CarouselInner,
  CarouselItem,
  CarouselWrapper,
} from "./style";
import useMovilSize from "../../../hooks/useMovilSize";
import { useNavigate } from "react-router-dom";

const CarouselProducts = ({ items, autoPlay = true, autoPlayTime = 3000 }) => {
  const { MovilSize } = useMovilSize();
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const itemsToShow = MovilSize ? 1 : 3;
  const totalItems = items?.length || 0;
  const uploadsPath = process.env.REACT_APP_IMAGES_URL;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
  };

  const redirectToProductDetails = (productId) => {
    navigate(`/product/${productId}`);
  };

  // Configurar autoplay con control estricto de dependencias
  useEffect(() => {
    if (autoPlay && totalItems > 1) {
      const interval = setInterval(nextSlide, autoPlayTime);
      return () => clearInterval(interval);
    }
  }, [autoPlay, autoPlayTime, totalItems, currentIndex]);

  return (
    <CarouselWrapper>
      <CarouselInner
        style={{
          transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)`,
          width: `${(totalItems * 100) / itemsToShow}%`,
        }}
      >
        {items?.map((item) => (
          <CarouselItem
            key={item._id}
            style={{
              flex: `0 0 ${100 / itemsToShow}%`,
            }}
          >
            <img
              src={`${uploadsPath}${item?.imgs[1]?.url}`}
              alt={item.description}
              onClick={() => redirectToProductDetails(item._id)}
            />
          </CarouselItem>
        ))}
      </CarouselInner>
      <CarouselControl prev onClick={prevSlide}>
        <LeftOutlined />
      </CarouselControl>
      <CarouselControl next onClick={nextSlide}>
        <RightOutlined />
      </CarouselControl>
    </CarouselWrapper>
  );
};

export default CarouselProducts;
