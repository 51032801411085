import { Button, Col, Radio, Row } from "antd";
import styled from "styled-components";

const ContentHeader = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .container {
    margin-right: 30px;
  }
`;

const ContainerSizes = styled(Row)`
  text-align: center;
`;

const ContentInitialSizes = styled(Row)`


`;

const ContentNamePiece = styled(Col)`
  span {
    font-size: 0.9rem;
    font-family: Montserrat, sans-serif;

text-transform: uppercase;
font-weight: 800;
line-height: 40px;
  }
`;

const ContentSizes = styled(Row)`
  padding: 20px;
`;
const ButtonSize = styled(Button)`
  max-width: 35px;
  justify-content: center;
  span {
    text-align: center;
    font-size: 0.8rem;
  }
`;

const TextNameSelectPiece = styled(Row)`
  margin-top: 20px;
  text-align: center;
  span {
    color: #919191;
  }
`;

const ButtonSelectedType = styled(Button)`
  margin-bottom: 10px !important;
  background-color: ${(props) => props.backgroundColor} !important;
  color: ${(props) => props.color} !important;
  max-width: 80px !important;
  max-height: 30px !important;
  border: none;

  &:hover {
    background-color: black !important;
    color: white !important;
  }

  span {
    text-transform: capitalize;
    font-size: 0.9rem !important;
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;
const ContentFooter = styled(Row)`
  min-height: 300px;
  width: 100%;
`;



const StyledRadioButton = styled(Radio.Button)`
  &.ant-radio-button-wrapper-checked {
    background-color: black;
    color: white;
    border-color: black;
  }

  &:hover {
    background-color: black;
    color: white;
  }
`;

export {
  ContentHeader,
  StyledRadioButton,
  ContainerSizes,
  ButtonSize,
  ContentSizes,
  ContentInitialSizes,
  ContentNamePiece,
  TextNameSelectPiece,
  ContentFooter,
  ButtonSelectedType,
};
