import { Row } from "antd";
import styled from "styled-components";
const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CarouselItem = styled.div`
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 180px;
    min-width: 180px;
    border-radius: 50%;
  }
`;

const CarouselInner = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: ${(props) => props.width || "100%"};
`;

const CarouselControl = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  color: black;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;

  &:focus {
    outline: none;
  }

  ${({ prev }) =>
    prev &&
    `
        left: 10px;
    `}

  ${({ next }) =>
    next &&
    `
        right: 10px;
    `}

    @media (max-width: 768px) {
    padding: 8px;
  }
`;

const ContentCarousel = styled(Row)`
  margin-top: 60px;
  margin-bottom: 60px;

  align-self: center;
  justify-content: center;
`;

export {
  CarouselWrapper,
  CarouselInner,
  CarouselItem,
  CarouselControl,
  ContentCarousel,
};
