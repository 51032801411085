// Footer.js
import React from "react";
import { ContentIcon, ContentInfo, FooterContainer, TitleInfo } from "./style";
import {
  UserOutlined,
  MobileOutlined,
  WhatsAppOutlined,
  InstagramOutlined,
  PinterestOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import {
  viewsFooterAbout,
  viewsFooterConstants,
} from "../../constants/viewsFooterConstants";
import FormSubscribe from "../formSubscribe/FormSubscribe";

const FooterCustom = () => {
  return (
    <>
      <Col xs={24} md={24}>
        <FooterContainer>
          <Col xs={24} md={24}>
            <FormSubscribe />
          </Col>

          <Row justify={"center"} style={{ background: "#212121" }}>
            <Col xs={24} md={14}>
              <ContentInfo>
                <Col xs={8} md={8}>
                  <Row justify={"center"}>
                    <Col md={8} xs={18}>
                      <TitleInfo>
                        <MobileOutlined /> Servicios
                      </TitleInfo>

                      {viewsFooterConstants.map((view) => (
                        <Row key={view._id} className="ContentLinkOptions">
                          <Link className="link" to={`/InfoVerona/${view.id}`}>
                            {view.label}
                          </Link>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Col>
                <Col xs={8} md={8}>
                  <Row justify={"center"}>
                    <Col md={8} xs={18}>
                      <TitleInfo>
                        <UserOutlined /> Nosotros
                      </TitleInfo>

                      {viewsFooterAbout.map((view) => (
                        <div key={view._id} className="ContentLinkOptions">
                          <Link className="link" to={`/InfoVerona/${view.id}`}>
                            {view.label}
                          </Link>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </Col>
                <Col xs={8} md={8}>
                  <Row justify={"center"}>
                    <Col md={10} xs={18}>
                      <TitleInfo>
                        <CopyOutlined /> Frecuentes
                      </TitleInfo>
                      {viewsFooterAbout.map((view) => (
                        <Row key={view._id} className="ContentLinkOptions">
                          <Link className="link" to={`/InfoVerona/${view.id}`}>
                            {view.label}
                          </Link>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Col>
                {/* <Col xs={6} md={6}>
                <span>Services</span>
              </Col>
              <Col xs={6} md={6}>
                <span>About</span>
              </Col>
              <Col xs={6} md={6}>
                <span>Terms</span>
              </Col> */}
              </ContentInfo>
            </Col>
            <Col xs={24} md={14}>
              <Row justify={"center"} style={{ marginBottom: "20px" }}>
                <Col xs={24} md={8}>
                  <Row justify={"center"}>
                    <ContentIcon md={8} xs={8}>
                      <a
                        href="https://api.whatsapp.com/send?phone=573246097414&text=Bienvenid%40s%20a%20la%20l%C3%ADnea%20de%20atenci%C3%B3n%20verona%20%E2%98%80%EF%B8%8F%2C%20ser%C3%A1%20un%20gusto%20%20tomar%20tu%20pedido%E2%80%A6%20%20deseas%20ver%20el%20cat%C3%A1logo%20"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <WhatsAppOutlined style={{ fontSize: "40px" }} />
                      </a>
                    </ContentIcon>
                    <ContentIcon md={8} xs={8}>
                      <a
                        href="https://www.instagram.com/lenceria_verona/?hl=es"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramOutlined style={{ fontSize: "40px" }} />
                      </a>
                    </ContentIcon>{" "}
                    <ContentIcon md={8} xs={8}>
                      <a
                        href="https://www.instagram.com/lenceria_verona/?hl=es"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PinterestOutlined style={{ fontSize: "40px" }} />
                      </a>
                    </ContentIcon>{" "}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </FooterContainer>
      </Col>
    </>
  );
};

export default FooterCustom;
